<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div>
      <table>
        <tr>
          <td id="head">
            <div>
              <h3>POPULAR MOVIES</h3>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div id="card">
              <b-card
                title="Bob Loves Abishola"
                :img-src="require('@/assets/boblovesshola.png')"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; position: relative"
                class="mb-2">
                <!-- Star Rating Overlay -->
                <div class="star-rating-overlay">
                  <span v-for="i in 5" :key="i" class="star" :class="{filled: i <= 4}"
                    >&#9733;</span
                  >
                </div>

                <!-- Card Content -->
                <b-card-text id="texr">
                  "Bob Hearts Abishola" is a romantic comedy television series that premiered on
                  September 23, 2019.
                </b-card-text>

                <!-- View Details Button -->
                <router-link :to="{name: 'bob'}">
                  <b-button variant="primary">View Details</b-button>
                </router-link>
              </b-card>
            </div>
          </td>
          <td>
            <div id="card">
              <b-card
                title="Spider-Man 2"
                :img-src="require('@/assets/spider.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; position: relative"
                class="mb-2">
                <!-- Star Rating Overlay -->
                <div class="star-rating-overlay">
                  <span v-for="i in 5" :key="i" class="star" :class="{filled: i <= 4}"
                    >&#9733;</span
                  >
                </div>

                <!-- Card Content -->
                <b-card-text id="texr">
                  "Spider-Man 2" is a superhero film directed by Sam Raimi and released in 2004.
                  starring Tobey Maguire as Peter.
                </b-card-text>

                <!-- View Details Button -->
                <router-link :to="{name: 'spider'}">
                  <b-button variant="primary">View Details</b-button>
                </router-link>
              </b-card>
            </div>
          </td>
          <td>
            <div id="card">
              <b-card
                title="Family Matters"
                :img-src="require('@/assets/familymatters.png')"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; position: relative"
                class="mb-2">
                <!-- Star Rating Overlay -->
                <div class="star-rating-overlay">
                  <span v-for="i in 5" :key="i" class="star" :class="{filled: i <= 4}"
                    >&#9733;</span
                  >
                </div>

                <!-- Card Content -->
                <b-card-text id="texr">
                  "Family Matters" is an American television sitcom that originally aired on
                  September 22, 1989.
                </b-card-text>

                <!-- View Details Button -->
                <router-link :to="{name: 'family'}">
                  <b-button variant="primary">View Details</b-button>
                </router-link>
              </b-card>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div id="did">
      <table border="0" width="100%">
        <tr>
          <td width="50%">
            <table width="100%">
              <tr>
                <td width="100%">
                  <img id="img" src="../assets/justice.png" />
                </td>
              </tr>
            </table>
          </td>

          <td width="50%">
            <table width="100%" border="0">
              <tr>
                <td id="tt">
                  <h2>Explore All The Corners Of<br />The Movie World With Us</h2>
                </td>
              </tr>
              <hr
                style="
                  height: 4px;
                  width: 60%;
                  background-color: #66ccff;
                  margin: 0;
                  border: none;
                " />
              <tr>
                <td id="ist">
                  <p>
                    "Step into the heartwarming world of 'Home' – a captivating film that celebrates
                    the magic of family, love, and the place we call home. Join our characters on an
                    unforgettable journey filled with laughter, tears, and moments that resonate
                    with the essence of what truly matters. 'Home' is not just a movie; it's an
                    emotional voyage that will leave you cherishing the bonds that make a house a
                    home. Experience the joy of 'Home' – where every frame tells a story, and every
                    story feels like home."
                  </p>
                </td>
              </tr>
              <tr>
                <table id="tss">
                  <tr align="left">
                    <td id="tss">
                      <router-link :to="{name: 'home'}">
                        <b-button variant="primary">Go Home</b-button></router-link
                      >
                    </td>
                    <td id="tss">
                      <router-link :to="{name: 'movies'}">
                        <b-button variant="primary">Go Movies</b-button></router-link
                      >
                    </td>
                  </tr>
                  <tr align="left">
                    <td id="tss">
                      <router-link :to="{name: 'about'}">
                        <b-button variant="primary">About Us</b-button></router-link
                      >
                    </td>
                    <td id="tss">
                      <router-link :to="{name: 'contact'}">
                        <b-button variant="primary">Contact Us</b-button></router-link
                      >
                    </td>
                  </tr>
                </table>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="container">
      <div class="mov-list">
        <table>
          <tr>
            <td><h2 id="tee">All Round Movies</h2></td>
          </tr>
        </table>
        <!-- Display Seasonal Movies -->
        <div v-for="sea in seasonal" :key="sea.id" class="mov-item">
          <router-link :to="{name: 'seasonal', params: {id: sea.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${sea.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - sea.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in sea.rating" :key="n" class="star filled"> &#9733; </span>
                  </div>
                </div> -->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ sea.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Display Cartoons Movies -->
        <div v-for="car in cartoons" :key="car.id" class="mov-item">
          <router-link :to="{name: 'cartoons', params: {id: car.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${car.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - car.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in car.rating" :key="n" class="star filled"> &#9733; </span>
                  </div> 
                </div>-->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ car.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Display Musical Movies -->
        <div v-for="mus in musical" :key="mus.id" class="mov-item">
          <router-link :to="{name: 'musical', params: {id: mus.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${mus.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - mus.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in mus.rating" :key="n" class="star filled"> &#9733; </span>
                  </div>
                </div> -->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ mus.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Display Adventure Movies -->
        <div v-for="adv in adventure" :key="adv.id" class="mov-item">
          <router-link :to="{name: 'adventure', params: {id: adv.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${adv.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - adv.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in adv.rating" :key="n" class="star filled"> &#9733; </span>
                  </div>
                </div> -->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ adv.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Display Horror Movies -->
        <div v-for="hor in horror" :key="hor.id" class="mov-item">
          <router-link :to="{name: 'horror', params: {id: hor.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${hor.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - hor.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in hor.rating" :key="n" class="star filled"> &#9733; </span>
                  </div>
                </div> -->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ hor.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Display Romance Movies -->
        <div v-for="rom in romance" :key="rom.id" class="mov-item">
          <router-link :to="{name: 'romance', params: {id: rom.id}}" class="active">
            <div class="mov-container">
              <div class="image-container">
                <img :src="require(`@/assets/${rom.image}`)" alt="Mov Image" />
                <!-- <div class="rating-container">
                  <div class="star-rating">
                    <span v-for="n in 5 - rom.rating" :key="n" class="star"> &#9733; </span>
                    <span v-for="n in rom.rating" :key="n" class="star filled"> &#9733; </span>
                  </div>
                </div> -->
              </div>
              <div class="mov-details">
                <hr class="hr-center" />
                <h3>{{ rom.title }}</h3>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  data() {
    return {
      rating: 4, // Set the initial rating here
    };
  },
  computed: {
    reversedRating() {
      // Create an array from 5 to 1
      return Array.from({length: 5}, (_, index) => 5 - index);
    },
  },

  data() {
    return {
      rating: 4, // Set the initial rating here
      seasonal: [{title: "Seasonal Movies", id: 2, image: "forsea.png", price: "$60", rating: 3}],
      cartoons: [{title: "Cartoons", id: 3, image: "cartoons.png", price: "$70", rating: 5}],
      musical: [{title: "Musical Movies", id: 4, image: "Musicals.png", price: "$10", rating: 4}],
      adventure: [
        {title: "Adventure Movies", id: 5, image: "adventure.png", price: "$15", rating: 3},
      ],
      horror: [{title: "Horror Movies", id: 6, image: "horror.png", price: "$12", rating: 5}],
      romance: [{title: "Romance", id: 7, image: "romance.png", price: "$13", rating: 2}],
    };
  },
};
</script>

<style scoped>
.star-rating-overlay {
  position: absolute;
  top: 10px; /* Adjust top position as needed */
  left: 10px; /* Adjust left position as needed */
  font-size: 24px;
  color: gold;
}

#texr {
  text-align: center;
}

.star {
  cursor: pointer;
  margin-right: 5px;
}

.filled {
  color: gold;
}
#card {
  padding-left: 18%;
  padding-top: 5%;
  padding-bottom: 5%;
}
#head {
  padding-top: 5%;
}
#img {
  width: 50%;
  padding-bottom: 5%;
  padding-top: 5%;
}
h2 {
  text-align: left;
  height: "20%";
  padding-top: "50%";
}
p {
  text-align: left;
  height: 70%;
  width: 80%;
}
#tss {
  align-items: left;
  padding-bottom: 10%;
}
#ist {
  height: 50%;
}
#tt {
  height: 23%;
}
#did {
  background-color: rgb(192, 192, 192);
}
#new {
  background-color: rgb(192, 192, 192);
}
.active {
  text-decoration: none;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

#tee {
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.mov-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mov-item {
  height: 17em;
  flex: 0 0 30%; /* Adjust the width as needed */
  margin-bottom: 20px;
}

.mov-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  height: 100%;
}

.mov-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 200px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.star-rating {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.star {
  color: #ddd;
  font-size: 20px;
  margin-right: 2px;
}

.star.filled {
  color: #ffc107;
}

.rating-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.mov-details {
  padding: 10px;
  text-align: center;
}

.mov-details h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.mov-details hr {
  border: 0.5px solid #ccc;
  margin: 8px 0;
}

.mov-details p {
  margin: 0;
  font-size: 14px;
  color: #555;
}
.hr-center {
  margin: 0 auto;
  height: 0;
  width: 100%; /* Adjust the width as needed */
}
</style>
