import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/congrat",
    name: "congrat",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "form" */ "../views/CongratView.vue"),
  },
  {
    path: "/movies",
    name: "movies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "movies" */ "../views/movies/MoviesView.vue"),
  },
  {
    path: "/form",
    name: "form",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "form" */ "../views/FormView.vue"),
  },
  {
    path: "/movies/:id",
    name: "action",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "action" */ "../views/movies/ActionView.vue"),
    props: true,
  },
  //redirect
  {
    path: "/action",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "seasonal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "seasonal" */ "../views/movies/SeasonalView.vue"),
    props: true,
  },
  {
    path: "/seasonal",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "cartoons",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cartoons" */ "../views/movies/CartoonsView.vue"),
    props: true,
  },
  {
    path: "/cartoons",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "musical",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "musical" */ "../views/movies/MusicalView.vue"),
    props: true,
  },
  {
    path: "/musical",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "adventure",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adventure" */ "../views/movies/AdventureView.vue"),
    props: true,
  },
  {
    path: "/adventure",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "horror",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "horror" */ "../views/movies/HorrorView.vue"),
    props: true,
  },
  {
    path: "/horror",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "romance",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "romance" */ "../views/movies/RomanceView.vue"),
    props: true,
  },
  {
    path: "/romance",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "comedy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "comedy" */ "../views/movies/ComedyView.vue"),
    props: true,
  },
  {
    path: "/comedy",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "science",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "science" */ "../views/movies/ScienceView.vue"),
    props: true,
  },
  {
    path: "/science",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "drama",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "drama" */ "../views/movies/DramaView.vue"),
    props: true,
  },
  {
    path: "/drama",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "thriller",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thriller" */ "../views/movies/ThrillerView.vue"),
    props: true,
  },
  {
    path: "/thriller",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "fantasy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fantasy" */ "../views/movies/FantasyView.vue"),
    props: true,
  },
  {
    path: "/fantasy",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "western",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "western" */ "../views/movies/WesternView.vue"),
    props: true,
  },
  {
    path: "/western",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "historical",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "historical" */ "../views/movies/HistoricalView.vue"),
    props: true,
  },
  {
    path: "/historical",
    redirect: "/movies",
  },
  {
    path: "/movies/:id",
    name: "hybrid",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hybrid" */ "../views/movies/HybridView.vue"),
    props: true,
  },
  {
    path: "/hybrid",
    redirect: "/movies",
  },
  {
    path: "/details/:id",
    name: "bob",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bob" */ "../views/movies/details/BobView.vue"),
    props: true,
  },
  {
    path: "/bob",
    redirect: "/romance",
  },
  {
    path: "/details/:id",
    name: "family",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "family" */ "../views/movies/details/FamilyView.vue"),
    props: true,
  },
  {
    path: "/family",
    redirect: "/comedy",
  },
  {
    path: "/details/:id",
    name: "spider",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "spider" */ "../views/movies/details/SpiderView.vue"),
    props: true,
  },
  {
    path: "/spider",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/movies/details/DetailView.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/movies/details/DetailView.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail1" */ "../views/movies/details/DetailView1.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail2" */ "../views/movies/details/DetailView2.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detai3" */ "../views/movies/details/DetailView3.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail4" */ "../views/movies/details/DetailView4.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail5" */ "../views/movies/details/DetailView5.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail6" */ "../views/movies/details/DetailView6.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail7" */ "../views/movies/details/DetailView7.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail8" */ "../views/movies/details/DetailView8.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail9" */ "../views/movies/details/DetailView9.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail01",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail01" */ "../views/movies/details/DetailView01.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail02",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail02" */ "../views/movies/details/DetailView02.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail03",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail03" */ "../views/movies/details/DetailView03.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail04",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail04" */ "../views/movies/details/DetailView04.vue"),
    props: true,
  },
  {
    path: "/detail",
    redirect: "/action",
  },
  {
    path: "/details/:id",
    name: "detail05",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail05" */ "../views/movies/details/DetailView05.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sea1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea1" */ "../views/movies/details/SeaView1.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea2" */ "../views/movies/details/SeaView2.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea3" */ "../views/movies/details/SeaView3.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea4" */ "../views/movies/details/SeaView4.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea5" */ "../views/movies/details/SeaView5.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea6" */ "../views/movies/details/SeaView6.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea7" */ "../views/movies/details/SeaView7.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea8" */ "../views/movies/details/SeaView8.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sea9" */ "../views/movies/details/SeaView9.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea10" */ "../views/movies/details/SeaView10.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea11" */ "../views/movies/details/SeaView11.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea12" */ "../views/movies/details/SeaView12.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea13" */ "../views/movies/details/SeaView13.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea14" */ "../views/movies/details/SeaView14.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "sea15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sea15" */ "../views/movies/details/SeaView15.vue"),
    props: true,
  },
  {
    path: "/sea",
    redirect: "/seasonal",
  },
  {
    path: "/details/:id",
    name: "car1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car1" */ "../views/movies/details/CarView1.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car2" */ "../views/movies/details/CarView2.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car3" */ "../views/movies/details/CarView3.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car4" */ "../views/movies/details/CarView4.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car5" */ "../views/movies/details/CarView5.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car6" */ "../views/movies/details/CarView6.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car7" */ "../views/movies/details/CarView7.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car8" */ "../views/movies/details/CarView8.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "car9" */ "../views/movies/details/CarView9.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car10" */ "../views/movies/details/CarView10.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car11" */ "../views/movies/details/CarView11.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car12" */ "../views/movies/details/CarView12.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car13" */ "../views/movies/details/CarView13.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car14" */ "../views/movies/details/CarView14.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "car15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "car15" */ "../views/movies/details/CarView15.vue"),
    props: true,
  },
  {
    path: "/car",
    redirect: "/cartoons",
  },
  {
    path: "/details/:id",
    name: "mus1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus1" */ "../views/movies/details/MusView1.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus2" */ "../views/movies/details/MusView2.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus3" */ "../views/movies/details/MusView3.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus4" */ "../views/movies/details/MusView4.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus5" */ "../views/movies/details/MusView5.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus6" */ "../views/movies/details/MusView6.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus7" */ "../views/movies/details/MusView7.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus8" */ "../views/movies/details/MusView8.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mus9" */ "../views/movies/details/MusView9.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus10" */ "../views/movies/details/MusView10.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus11" */ "../views/movies/details/MusView11.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus12" */ "../views/movies/details/MusView12.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus13" */ "../views/movies/details/MusView13.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus14" */ "../views/movies/details/MusView14.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },
  {
    path: "/details/:id",
    name: "mus15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "mus15" */ "../views/movies/details/MusView15.vue"),
    props: true,
  },
  {
    path: "/mus",
    redirect: "/musical",
  },

  {
    path: "/details/:id",
    name: "adv2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv2" */ "../views/movies/details/AdvView2.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv3" */ "../views/movies/details/AdvView3.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv4" */ "../views/movies/details/AdvView4.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv5" */ "../views/movies/details/AdvView5.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv6" */ "../views/movies/details/AdvView6.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv7" */ "../views/movies/details/AdvView7.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv8" */ "../views/movies/details/AdvView8.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "adv9" */ "../views/movies/details/AdvView9.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv10" */ "../views/movies/details/AdvView10.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv11" */ "../views/movies/details/AdvView11.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv12" */ "../views/movies/details/AdvView12.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv13" */ "../views/movies/details/AdvView13.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv14" */ "../views/movies/details/AdvView14.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "adv15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adv15" */ "../views/movies/details/AdvView15.vue"),
    props: true,
  },
  {
    path: "/adv",
    redirect: "/adventure",
  },
  {
    path: "/details/:id",
    name: "hor0",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor0" */ "../views/movies/details/HorView0.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor1" */ "../views/movies/details/HorView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor2" */ "../views/movies/details/HorView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor3" */ "../views/movies/details/HorView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor4" */ "../views/movies/details/HorView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor5" */ "../views/movies/details/HorView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor6" */ "../views/movies/details/HorView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor7" */ "../views/movies/details/HorView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hor9" */ "../views/movies/details/HorView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor10" */ "../views/movies/details/HorView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor11" */ "../views/movies/details/HorView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor12" */ "../views/movies/details/HorView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor13" */ "../views/movies/details/HorView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor14" */ "../views/movies/details/HorView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hor15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hor15" */ "../views/movies/details/HorView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom2" */ "../views/movies/details/RomView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom3" */ "../views/movies/details/RomView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom4" */ "../views/movies/details/RomView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom5" */ "../views/movies/details/RomView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom6" */ "../views/movies/details/RomView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom7" */ "../views/movies/details/RomView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom8" */ "../views/movies/details/RomView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rom9" */ "../views/movies/details/RomView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom10" */ "../views/movies/details/RomView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom11" */ "../views/movies/details/RomView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom12" */ "../views/movies/details/RomView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom13" */ "../views/movies/details/RomView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom14" */ "../views/movies/details/RomView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "rom15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rom15" */ "../views/movies/details/RomView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com2" */ "../views/movies/details/ComView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com3" */ "../views/movies/details/ComView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com4" */ "../views/movies/details/ComView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com5" */ "../views/movies/details/ComView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com6" */ "../views/movies/details/ComView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com7" */ "../views/movies/details/ComView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com8" */ "../views/movies/details/ComView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "com9" */ "../views/movies/details/ComView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com10" */ "../views/movies/details/ComView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com11" */ "../views/movies/details/ComView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com12" */ "../views/movies/details/ComView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com13" */ "../views/movies/details/ComView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com13" */ "../views/movies/details/ComView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "com15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "com15" */ "../views/movies/details/ComView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci1" */ "../views/movies/details/SciView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci2" */ "../views/movies/details/SciView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci3" */ "../views/movies/details/SciView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci4" */ "../views/movies/details/SciView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci5" */ "../views/movies/details/SciView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci6" */ "../views/movies/details/SciView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci7" */ "../views/movies/details/SciView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci8" */ "../views/movies/details/SciView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sci9" */ "../views/movies/details/SciView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci10" */ "../views/movies/details/SciView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci11" */ "../views/movies/details/SciView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci12" */ "../views/movies/details/SciView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci13" */ "../views/movies/details/SciView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci14" */ "../views/movies/details/SciView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "sci15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sci15" */ "../views/movies/details/SciView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra1" */ "../views/movies/details/DraView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra2" */ "../views/movies/details/DraView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra3" */ "../views/movies/details/DraView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra4" */ "../views/movies/details/DraView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra5" */ "../views/movies/details/DraView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra6" */ "../views/movies/details/DraView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra7" */ "../views/movies/details/DraView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra8" */ "../views/movies/details/DraView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dra9" */ "../views/movies/details/DraView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra10" */ "../views/movies/details/DraView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra11" */ "../views/movies/details/DraView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra12" */ "../views/movies/details/DraView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra13" */ "../views/movies/details/DraView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra14" */ "../views/movies/details/DraView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "dra15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dra15" */ "../views/movies/details/DraView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr1" */ "../views/movies/details/ThrView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr2" */ "../views/movies/details/ThrView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr3" */ "../views/movies/details/ThrView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr4" */ "../views/movies/details/ThrView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr5" */ "../views/movies/details/ThrView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr6" */ "../views/movies/details/ThrView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr7" */ "../views/movies/details/ThrView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr8" */ "../views/movies/details/ThrView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "thr9" */ "../views/movies/details/ThrView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr10" */ "../views/movies/details/ThrView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr11" */ "../views/movies/details/ThrView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr12" */ "../views/movies/details/ThrView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr13" */ "../views/movies/details/ThrView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr14" */ "../views/movies/details/ThrView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "thr15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "thr15" */ "../views/movies/details/ThrView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan1" */ "../views/movies/details/FanView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan2" */ "../views/movies/details/FanView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan3" */ "../views/movies/details/FanView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan4" */ "../views/movies/details/FanView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan5" */ "../views/movies/details/FanView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan6" */ "../views/movies/details/FanView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan7" */ "../views/movies/details/FanView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan8" */ "../views/movies/details/FanView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "fan9" */ "../views/movies/details/FanView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan10" */ "../views/movies/details/FanView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan11" */ "../views/movies/details/FanView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan12" */ "../views/movies/details/FanView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan13" */ "../views/movies/details/FanView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan14" */ "../views/movies/details/FanView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "fan15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "fan15" */ "../views/movies/details/FanView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his1" */ "../views/movies/details/HisView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his2" */ "../views/movies/details/HisView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his3" */ "../views/movies/details/HisView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his4" */ "../views/movies/details/HisView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his5" */ "../views/movies/details/HisView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his6" */ "../views/movies/details/HisView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his7" */ "../views/movies/details/HisView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his8" */ "../views/movies/details/HisView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "his9" */ "../views/movies/details/HisView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his10" */ "../views/movies/details/HisView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his11" */ "../views/movies/details/HisView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his12" */ "../views/movies/details/HisView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his13" */ "../views/movies/details/HisView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his14" */ "../views/movies/details/HisView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "his15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "his15" */ "../views/movies/details/HisView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes1" */ "../views/movies/details/WesView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes2" */ "../views/movies/details/WesView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes3" */ "../views/movies/details/WesView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes4" */ "../views/movies/details/WesView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes5" */ "../views/movies/details/WesView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes6" */ "../views/movies/details/WesView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes7" */ "../views/movies/details/WesView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes8" */ "../views/movies/details/WesView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wes9" */ "../views/movies/details/WesView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes10" */ "../views/movies/details/WesView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes11" */ "../views/movies/details/WesView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes12" */ "../views/movies/details/WesView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes13" */ "../views/movies/details/WesView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes14" */ "../views/movies/details/WesView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "wes15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wes15" */ "../views/movies/details/WesView15.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb1" */ "../views/movies/details/HybView1.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb2" */ "../views/movies/details/HybView2.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb3",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb3" */ "../views/movies/details/HybView3.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb4",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb4" */ "../views/movies/details/HybView4.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb5",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb5" */ "../views/movies/details/HybView5.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb6",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb6" */ "../views/movies/details/HybView6.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb7",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb7" */ "../views/movies/details/HybView7.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb8",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb8" */ "../views/movies/details/HybView8.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb9",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hyb9" */ "../views/movies/details/HybView9.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb10",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb10" */ "../views/movies/details/HybView10.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb11",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb11" */ "../views/movies/details/HybView11.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb12",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb12" */ "../views/movies/details/HybView12.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb13",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb13" */ "../views/movies/details/HybView13.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb14",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb14" */ "../views/movies/details/HybView14.vue"),
    props: true,
  },
  {
    path: "/details/:id",
    name: "hyb15",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hyb15" */ "../views/movies/details/HybView15.vue"),
    props: true,
  },
  //Catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
