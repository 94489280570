<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-navbar-brand href="#">Movie Home</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <button @click="redirect" class="button">Redirect</button>
          <button @click="back" class="button">Go Back</button>
          <button @click="forward" class="button">Go Forward</button>
          <!-- <b-navbar-nav>
            <b-nav-item href="#">Link</b-nav-item>
            <b-nav-item href="#" disabled>Disabled</b-nav-item>
          </b-navbar-nav> -->

          <!-- Right aligned nav items -->
          <!-- <b-navbar-nav class="ml-auto">
            <div>
              <b-navbar type="light" variant="rgb(0,0,0)">
                <b-nav-form>
                  <b-form-input class="mr-sm-2" placeholder="Search"></b-form-input>
                  <b-button variant="outline-success" id="search" class="my-2 my-sm-0" type="submit"
                    >Search</b-button
                  >
                </b-nav-form>
              </b-navbar>
            </div>
            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
             
              <template #button-content>
                <em>User</em>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item href="#">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav> -->
        </b-collapse>
      </b-navbar>
    </div>

    <!--Slider Section-->
    <template>
      <div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd">
          <b-carousel-slide
            caption=""
            :img-src="require('@/assets/adventure.png')"
            @click="redirect"
            class="img-fluid"
            style="object-fit: cover; width: 1500px; height: 430px"></b-carousel-slide>
          <b-carousel-slide
            caption=""
            :img-src="require('@/assets/cartoons.png')"
            @click="back"
            class="img-fluid"
            style="object-fit: cover; width: 1500px; height: 430px"></b-carousel-slide>
          <b-carousel-slide
            caption=""
            :img-src="require('@/assets/genre.png')"
            @click="forward"
            class="img-fluid"
            style="object-fit: cover; width: 1500px; height: 430px"></b-carousel-slide>
          <b-carousel-slide
            caption=""
            :img-src="require('@/assets/fantasy.png')"
            @click="forward"
            class="img-fluid"
            style="object-fit: cover; width: 1500px; height: 430px"></b-carousel-slide>
          <b-carousel-slide
            caption=""
            :img-src="require('@/assets/west.png')"
            @click="forward"
            class="img-fluid"
            style="object-fit: cover; width: 1500px; height: 430px"></b-carousel-slide>
        </b-carousel>
      </div>

      <!--<div class="container">
        <div class="w-75 h-75">
          <div id="carouselExample" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExample" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExample" data-slide-to="1"></li>
              <li data-target="#carouselExample" data-slide-to="2"></li>
            </ol>
            <div class=carousel-inner>
              <b-carousel-slide class="carousel-item active">
                <img src="./assets/adventure.png" alt="First Slider" class="d-block w-100 h-100" />
              </b-carousel-slide>
              <b-carousel-slide class="carousel-item">
                <img
                  src="./assets/boblovesshola.png"
                  alt="Second Slider"
                  class="d-block w-100 h-100" />
              </b-carousel-slide>
              <b-carousel-slide class="carousel-item">
                <img src="./assets/65.png" alt="Third Slider" class="d-block w-100 h-100" />
              </b-carousel-slide>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExample"
              role="button"
              data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExample"
              role="button"
              data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>-->
    </template>
    <div id="app">
      <nav id="ppp">
        <router-link to="/" id="nap">Home</router-link> |
        <router-link to="/movies" id="nap">Movies</router-link> |
        <router-link to="/about" id="nap">About Us</router-link> |
        <router-link to="/contact" id="nap">Contact Us</router-link>
      </nav>
      <router-view />
    </div>
    <div id="new">
      <table>
        <tr>
          <td><h2 id="tee">Sign Up To Our Newsletter</h2></td>
        </tr>
        <div class="d-flex justify-content-center" id="new">
          <tr>
            <td>
              <b-navbar type="light">
                <b-nav-form>
                  <b-form-input
                    style="width: 300px"
                    class="mr-sm-2"
                    placeholder="Enter Your Email"></b-form-input>

                  <b-button
                    variant="outline-success"
                    class="my-2 my-sm-0"
                    style="
                      color: #2661df;
                      border: 1px solid #2661df;
                      box-shadow: 0 0 10px #2661df; /* Add box-shadow to set the initial shadow color */
                      transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                    "
                    @click="
                      $event.target.style.boxShadow =
                        '0 0 10px #2661df' /* Set the clicked shadow color */
                    "
                    @mouseover="
                      $event.target.style.backgroundColor = '#2661df';
                      $event.target.style.color = '#fff';
                      $event.target.style.borderColor = '#fff';
                    "
                    @mouseout="
                      $event.target.style.backgroundColor = '';
                      $event.target.style.color = '#2661df';
                      $event.target.style.borderColor = '#2661df';
                    "
                    type="submit">
                    Submit
                  </b-button>
                </b-nav-form>
              </b-navbar>
            </td>
          </tr>
        </div>
      </table>
    </div>
    <div id="foot">
      <table>
        <tr>
          <td id="tds">
            <table>
              <tr>
                <td>
                  <h4>ABOUT US</h4>
                </td>
              </tr>
              <tr>
                <td>
                  Welcome to our movie haven! At Movie Home, we're passionate about bringing
                  cinematic magic to your home. Dive into a world of entertainment where every film
                  is a journey, and every moment is a story waiting to unfold. Join us for an
                  immersive experience that turns your home into a personal cinema. Lights, camera,
                  home action!
                </td>
              </tr>
            </table>
          </td>
          <td id="td">
            <table>
              <tr>
                <td>
                  <h4>QUICK LINK</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <router-link to="/contact" id="naps">Contact Us</router-link>
                </td>
              </tr>
              <tr>
                <td>
                  <router-link to="/about" id="naps">About Us</router-link>
                </td>
              </tr>

              <tr>
                <td>
                  <router-link to="/movies" id="naps">Movies</router-link>
                </td>
              </tr>
              <tr>
                <td>
                  <router-link to="/" id="naps">Home</router-link>
                </td>
              </tr>
            </table>
          </td>
          <td id="tds">
            <table>
              <tr>
                <td>
                  <h4>CONTACT US</h4>
                </td>
              </tr>
              <span id="pan">
                <tr>
                  <td>
                    <img id="img" src="./assets/map-marker.png" />
                    <span>
                      <span id="sap">61,Ogelu Palace Road, Igbo Ojigo</span><br /><span id="span"
                        >Ogun State</span
                      ></span
                    >
                  </td>
                </tr>
              </span>
              <span id="pan">
                <tr>
                  <td>
                    <img id="im" src="./assets/true.jpg" />
                    <span id="pas"> 0808-604-8944 </span>
                  </td>
                </tr>
              </span>
              <span>
                <tr>
                  <td>
                    <img id="imga" src="./assets/mail.png" />
                    <span> baloalamin27@gmail.com </span>
                  </td>
                </tr>
              </span>
              <tr>
                <td>
                  <a href="https://twitter.com/al_amin23386" target="_blank">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAlFJREFUWEft1kvoTVEUx/HP32sijzBRiLwyIyEDoaSQ8kzCCEVKKRRCyCuPoQzIwEQGkjBlRpISKYmQTAgTSXm1at/6d517zz5/9+Y/uLtOnTprrf09v7X3WqtLL1tdvYxHB6gsIx2F/odCE7EdszEFT3AfF9N7jakfJuFZd8iilA3DPFwr+5uC7/twEP0b+K7DU8zHNizF8zKgHTiFFbhRAWo9LlewP57gB+FTza9IoatYjZ9YhpsZm4zACwzNsA2TO/iM0Umtr82AQpWQMtYvnMUBfGuy2RpcyYSpmX1I5+xlWcr24mhd8LeIVF5PkPV7R4p3VgAKiAV4Xe9TlLK5uNsg+HtcSk/3P7uAjRWAduF0kX0RUB/EOVpZskFIHlDxTMCsCkCbET/x1yoCWogH6drH9WzHWoLbuUChznK8wfh20GAqHucCncTuNoFE2LjiQ1JZyUpZ0D+ibaNJdICG57NRtz+M/W1SaVPqa4Xhm40fcRPiag5uIdgXjEppqwy0CAMQVXhti6COpKrfMFwzhabjYYtAaod5LD42i1k2MZ7D1hZBhcql/a4MKL6fSX3sX7jO5/5YGVANYgZi3pmGORXJbqVr/j3HLxeoL7bgEIbnBE42kaIN+JHr0wwoZt4xqcwfw+TcoPiN8IlaFu/Zqx5ocWob41K9iM5fdUUPjAN8r6pj2BcpFErswSoMrBA0hvdoCydKpsumIZulLIpijB8x58xE1JCRCTImvVdp4ouuHQf3XQX4HhXGVsSvHCP3llUO3FOHDlCZch2FyhT6Az6rXyWgraXlAAAAAElFTkSuQmCC"
                  /></a>
                  <a href="https://www.instagram.com/baloalamin27" target="_blank"
                    ><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAA3pJREFUWEft12nIbXMUBvDfNYZ8IWOUZCZTSaQMkSn5YL5JEspUMhQyDxFCMmRKIjNRihBSppB5CqWIwkckc4/WuW377rP3Pvf4cD/cVafe89/rv/bzrvWsZ62zwFJmC5YyPJYBGqrILBnaBjtgIyw/FLie/4mv8A4+HHNnDKCjcCk2GROwx+dznI+H++L0AVoP92CfOYG0rz+HY/FtV9w+QM9g37r0Ku7G25X630eCXBnbYWecjC3q3tM4YBZAh+DRunAerhwJoM9tJdyAk8rpUDzWvjAtQ6l3OJN6HzEHmD2xEOvgqcryW9gWnzUytugVXYC2wkflsTtengPQKbipVaajcW+dbYhvmvG7ADXLlTRP48vqOBO7YHv8jXfxOq7Bz5XdB+uFaZCQOZlPBWIHVeZ6M5Qa34IfsdaU7OxdHbj+lOdf4xi8hMjG2pX157Eafqp7yWDe1QvobFyNL7Bpxwv3wIuN8xfwPlZAyr1X49lueKUVI1X5q87OxVVDgC4oIfygyNf0T5k+xgaVwRA2utK0SMX9WKNUemv80vL5o9T+Qlw2BOhiXIR0w06tQMfjjjqLjkRPuiwt/Ug9SOkmJJ74hpfJ6ChAl5RjyBnCNu0uHIfXsOsUMJPjzK+Q/dYSxab7r4hojgKUuZWyvVcBm4E+Ke1IK582AOh2nDAlTjpw1aJGqrHIutp+UrJPsWXrpTnbHDfj1AFAt+HE4lx41LR0Wbot/EmWRgEKeduBHsCRpTXtcrbxRZMyx8Kf8KhpIfkqyD8fiiwxoHMac20xUWvEPRwP1fczcH0L0G9Ysahx+RCg1DTIu0q2bk37NfEDMgaebb1sf9xXbf9dSUdEdmLLIYtbbLHB3cWhOF2BL6csZfu12r1PGCOSTRENiJA5pI5l9Fw3lKH/e3S0udUcHdmRIgu9HDoYT9SwjFb0DdeMmSxf2bUzDtrDtQ0m3zeu7OfvDPLHhwBlq4vexOZdP7oANdeP/HCYrDr/+g4taCHsZI3tCj7rWcbFmyW439fi9p8Y0wAd2NhTohP5ZN+Zx9orbOdq3LfkZ309rBC8gTtryc8WkGk9xiZLfob06Y2uTYwdu+L0AUp7XttYyscAGOOTLSDd1dSm3i5rBw2HbsRmY97W45OF7yw82RdnzC/Xyf2l5qf0nImZ7fosGZot8hJ6LwM0lLh/ALGquSVVFOHaAAAAAElFTkSuQmCC"
                  /></a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <!-- Horizontal Line Divider -->
                  <hr />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table id="copy">
        <tr>
          <td>
            <table>
              <tr>
                <td id="cop"><p>© Copyright 2023 | All Rights Reserved by Al-Amin</p></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirect() {
      this.$router.push({name: "home"});
    },
    back() {
      this.$router.go(-1);
    },
    forward() {
      this.$router.go(1);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ffff;
}

#nap {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ffff;
  text-decoration: none;
}
#nap:hover {
  color: #ffff;
  background-color: #2661df;
}

#naps {
  color: #ffff;
  text-decoration: none;
}

#tds {
  width: 33.3%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
}

#copy {
  text-align: center;
}

#new {
  background-color: rgb(192, 192, 192);
}

#tee {
  text-align: center;
}

hr {
  width: 302%; /* Adjust the width as needed */
  height: 1000px; /* Adjust the height as needed */
  border-color: #ccc; /* Color of the horizontal line */
  margin: 20px auto; /* Center the horizontal line and add margin */
}

#span {
  padding-left: 12%;
}

#td {
  width: 33.3%;
  text-align: center;
}

#naps:hover {
  color: #2661df;
}

#ppp {
  background-color: #33b4db;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
}

nav a.router-link-exact-active {
  color: #ffff;
  background-color: #ff0000;
}

#search {
  background-color: #ffff;
}

#search:hover {
  background-color: #2661df; /* Set the background color on hover */
}

#foot {
  height: 20em;
  color: #ffff;

  background-color: #33b4db;
}

table {
  width: 100%;
}

.button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}
.button:hover {
  color: #ffff;
  background-color: #2661df; /* Set the background color on hover */
}

#img {
  width: 6%;
  height: 50%;
}
#im {
  width: 5%;
  height: 50%;
}
#imga {
  width: 10%;
  height: 50%;
}
#pan {
  padding-left: 3%;
}
#pas {
  padding-left: 2%;
}
#sap {
  padding-left: 4%;
}
</style>
